/**
 * jQuery heartbeat plugin
 *
 * @author		Benjamin Hess <benjamin.hess@suxedo.de>
 *
 * This plugin will execute a callback function after a defined
 * time period. The timer will only run once and reactivate
 * itself, when an event was triggered. You can use any
 * kind of event, that is known and implemented by
 * the jQuery framework.
 *
 * e.g:
 *
 * $.heartbeat({
 *    click: true,
 *    keypress: true,
 *    mousemove: true,
 *    timeout: 3000
 * }, function(){
 *
 * })
 *
 * This will trigger the callback function every 3 seconds,
 * as long as you press some keys, move the mouse or click
 * the mouse buttons.
 */

(function($)
{
  // extend the jQuery framework
  $.extend($, {
    /**
     * Heartbeat
     *
     * See description above for more info
     *
     * @param object poEvents	object with events and timeout
     * @param function poCallback	callback function
     * @return void
     */
    heartbeat : function(poEvents, poCallback) {
      // if events object is undefined
      if(typeof poEvents == 'undefined') {
        return;
      }

      // if callback function is undefined
      if(typeof poCallback == 'undefined') {
        // assume that we got no events object
        // and the first param is the callback
        poCallback = poEvents;

        // create default events object
        // will execute the callback function
        // for mouse movements/clicks and keypresses
        poEvents = {
          keypress: true,
          mousemove: true,
          click: true
        }
      }

      // if callback param is no function
      if(typeof poCallback != 'function') {
        return;
      }

      // get timeout from events object or set it to one minute
      var iTimeout = ((typeof poEvents.timeout != 'undefined' && !isNaN(parseInt(poEvents.timeout)))?
        parseInt(poEvents.timeout) : 60000);

      // set to false, so the "run" function knows, that
      // there is no timeout running atm
      var bIsRunning = false;

      /**
       * Run method
       *
       * Will use 'setTimeout' to execute the callback function
       * after the given number of milliseconds, but only if there
       * is no previous 'setTimeout' function running atm!
       *
       * This way we make sure, that the callback function will
       * be executed only once for the given number of milliseconds
       * no mater how often the method was called!
       *
       * @return void
       */
      function run() {
        // if previous 'setTimeout' function is not running
        if(bIsRunning == false) {
          // set to true, so this method knows that we
          // are running a timeout function atm
          bIsRunning = true;

          // exec the given callback function after
          // the given number of milliseconds
          setTimeout(function() {
            // exec the given callback function
            poCallback();
            // set to false, so this method knows that we
            // are running no timeout function atm
            bIsRunning = false;
          }, iTimeout);
        }
      }

      // iterate over each event from the given events object
      $.each(poEvents, function(sEvent, bUse) {
        // if we should use the current event
        if(bUse && sEvent != 'timeout' && typeof $()[sEvent] != 'undefined') {
          // bind event handler for the current event
          // to the "run" method so it gets executed,
          // when the event is triggered!
          $('*')[sEvent](function(){run()})
        }
      });

      // exec the callback function
      poCallback();
    }
  })

})(jQuery);
